.card {
    min-width: 180px;
    @media (max-width: 768px) {
        //min-width: 220px;
      }
    &-column {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
  }