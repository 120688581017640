.input {
  position: relative;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  padding: 18px 30px;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  border: 2px solid transparent;

  @media (max-width: 575px) {
    padding: 15px;
  }

  &-danger {
    border-color: #FF3737;
  }

  &-light {
    background: #3F3B7B;
    border-radius: 15px;
    font-size: 20px;
    line-height: 24px;
  }

  label {
    display: block;
    margin-bottom: 5px;
    font-size: 16px;
    line-height: 19px;
    font-weight: 600;
    letter-spacing: -0.02em;
    color: rgba(255, 255, 255, 0.5);
  }

  input {
    border: none;
    background: none;
    color: #fff;
    font: inherit;
    outline: none;
    margin-right: 30px;
    width: calc(100% - 50px);
  }
  
  .copy {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='22' height='25' viewBox='0 0 22 25' fill='none' %3E%0A%3Cpath d='M3.37852 24.157C2.71852 24.157 2.15332 23.9218 1.68292 23.4514C1.21252 22.981 0.977718 22.4162 0.978518 21.757V4.95698H3.37852V21.757H16.5785V24.157H3.37852ZM8.17852 19.357C7.51852 19.357 6.95332 19.1218 6.48292 18.6514C6.01252 18.181 5.77772 17.6162 5.77852 16.957V2.55698C5.77852 1.89698 6.01372 1.33178 6.48412 0.861384C6.95452 0.390984 7.51932 0.156184 8.17852 0.156984H18.9785C19.6385 0.156984 20.2037 0.392184 20.6741 0.862584C21.1445 1.33298 21.3793 1.89778 21.3785 2.55698V16.957C21.3785 17.617 21.1433 18.1822 20.6729 18.6526C20.2025 19.123 19.6377 19.3578 18.9785 19.357H8.17852Z' fill='white'/%3E%0A%3C/svg%3E%0A");
    cursor: pointer;
    position: absolute;
    right: 30px;
    width: 23px;
    height: 24px;
    background-repeat: no-repeat;
  }
}